<template>
  <div class="container-form">
    <div class="subcontainer-form">
      <form @submit.prevent="handleSubmit" class="d-flex flex-column">
        <div class="mb-3 container-input d-flex">
          <input
            type="email"
            v-model="email"
            class="form-control"
            id="exampleInputEmail1"
            aria-describedby="emailHelp"
            placeholder="Email"
            required
          />
        </div>
        <div class="mb-3 container-input d-flex">
          <input
            type="text"
            v-model="video_resource"
            class="form-control"
            id="exampleInputEmail1"
            aria-describedby="emailHelp"
            placeholder="Youtube Video link"
            required
          />
        </div>
        <div class="mb-3 container-input d-flex">
          <select
            v-model="language"
            class="form-control custom-select"
            id="selectLanguage"
            required
          >
            <option value="" disabled selected hidden>Select language</option>
            <option value="it">Italian</option>
            <option value="en">English</option>
            <option value="fr">French</option>
            <option value="de">German</option>
            <option value="es">Spanish</option>
            <option value="pt">Portuguese</option>
            <option value="ru">Russian</option>
            <option value="ro">Romanian</option>
            <option value="ja">Japanese</option>
            <option value="ko">Korean</option>
            <option value="zh-cn">Chinese (Simplified)</option>
            <option value="zh-tw">Chinese (Traditional)</option>
          </select>

        </div>
        <!-- Spinner (mostrato solo quando isLoading è true) -->
        <div v-if="isLoading" class="spinner">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            height="2em"
            viewBox="0 0 512 512"
            class="white-spinner rotate"
          >
            <!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. -->
            <path
              d="M222.7 32.1c5 16.9-4.6 34.8-21.5 39.8C121.8 95.6 64 169.1 64 256c0 106 86 192 192 192s192-86 192-192c0-86.9-57.8-160.4-137.1-184.1c-16.9-5-26.6-22.9-21.5-39.8s22.9-26.6 39.8-21.5C434.9 42.1 512 140 512 256c0 141.4-114.6 256-256 256S0 397.4 0 256C0 140 77.1 42.1 182.9 10.6c16.9-5 34.8 4.6 39.8 21.5z"
            />
          </svg>
        </div>
        <div v-if="showSuccessMessage && !isLoading" class="success-message">
          you will receive the email shortly, check your mailbox (or in spam folder)
        </div>
        <div class="container-btn">
          <button
            type="submit"
            class="btn"
            v-if="!showSuccessMessage && !isLoading"
          >
            Summarize
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { getCopy } from "@/copy/index.js";

export default {
  name: "FormComponent",
  computed: {
    requestAccessButton() {
      const path = this.$route.path;
      return getCopy({ field: "requestAccessButton", landing_path: path });
    },
    requestAccessInput() {
      const path = this.$route.path;
      return getCopy({ field: "requestAccessInput", landing_path: path });
    },
  },
  data() {
    return {
      email: "",
      video_resource: "",
      language: "",
      showSuccessMessage: false,
      isLoading: false,
    };
  },
  methods: {
    async handleSubmit() {
      try {
        this.isLoading = true;

        const data = {
          email: this.email,
          video_resource: this.video_resource,
          language_code: this.language,
        };

        await axios.post(
          `${process.env.VUE_APP_BACKEND_URL}/api/summaries/send`,
          data
        );

        this.isLoading = false;
        this.showSuccessMessage = true;
      } catch (error) {
        console.error("API error:", error);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.container-form {

  .form-control {
    display: flex;
    width: 320px;
    padding: 9px 23.841px 6.577px 23.841px;
    justify-content: center;
    align-items: center;
    border-radius: 20.553px;
    border: 1.026px solid #7b68ee;
    background: #fff;
    box-shadow: 0px 3.28842px 3.28842px 0px rgba(0, 0, 0, 0.25);
    margin-right: 32px;
    text-align: center;
  }
 

  button {
    color: white;
    display: inline-flex;
    padding: 9px 23.227px 6.407px 23.227px;
    justify-content: center;
    align-items: center;
    border-radius: 20.023px;
    background: #7b68ee;
    box-shadow: 0px 3.2037px 3.2037px 0px rgba(0, 0, 0, 0.25);
  }

  .white-spinner {
    fill: #7b68ee;
  }
}

.container-form {
  margin-top: 100px;
}

.rotate {
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}


@media (max-width: 1199px) {
  form {
    flex-direction: column !important;
  }

}



@media (max-width: 600px) {
  .form-control {
    margin-right: 0px !important;
  }

  form {
    flex-direction: column !important;
  }

 
  .container-form {
    margin-top: 80px;
    margin-bottom: 80px;
  }

  .success-message{
    text-align: center;
  }
}
</style>
