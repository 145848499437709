<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>

export default {
  name: 'App',
  mounted() {
    document.title = 'QuickView';
  }
}
</script>

<style lang="scss">

// @import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');

* {
  margin: 0 auto;
  padding: 0;
  box-sizing: border-box;
}


@font-face {
  font-family: 'AxiformaRegular';
  src: url('@/assets/fonts/axiformaregular.otf') format('opentype');
}

@font-face {
  font-family: 'AxiformaBold';
  src: url('@/assets/fonts/axiformabold.otf') format('opentype');
}


#app {
  font-family: 'AxiformaBold', sans-serif;
  background-color: #FAFAFA;
}
</style>
