import {LANDINGS_PATH} from "@/constants"

const LANDING_TO_IMAGE_DESKTOP = {
  [LANDINGS_PATH.GENERAL]: 'image-landing.png',
  [LANDINGS_PATH.CRYPTO]: 'image-crypto.png',
  [LANDINGS_PATH.FINANCE]: 'image-finance.png',
  [LANDINGS_PATH.CODING]: 'image-coding.png'
}

const LANDING_TO_IMAGE_TABLET = {
  [LANDINGS_PATH.GENERAL]: 'tablet-landing.png',
  [LANDINGS_PATH.CRYPTO]: 'tablet-crypto.png',
  [LANDINGS_PATH.FINANCE]: 'tablet-finance.png',
  [LANDINGS_PATH.CODING]: 'tablet-coding.png'
}


const LANDING_TO_IMAGE_MOBILE = {
  [LANDINGS_PATH.GENERAL]: 'mobile-landing.png',
  [LANDINGS_PATH.CRYPTO]: 'mobile-crypto.png',
  [LANDINGS_PATH.FINANCE]: 'mobile-finance.png',
  [LANDINGS_PATH.CODING]: 'mobile-coding.png'
}

export const getBackgroundLandingImage = (landing_path, screen_width) => {
  // let constant = screen_width > 600 ? LANDING_TO_IMAGE_DESKTOP : LANDING_TO_IMAGE_MOBILE
  let constant

  if (screen_width > 1200){
    constant = LANDING_TO_IMAGE_DESKTOP
  } else if (screen_width > 600){
    constant = LANDING_TO_IMAGE_TABLET 
  } else {
    constant = LANDING_TO_IMAGE_MOBILE
  }

  return constant[landing_path] || constant[LANDINGS_PATH.GENERAL]
}