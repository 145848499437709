export const LANDINGS_PATH = {
  GENERAL: '/',
  CRYPTO: '/crypto',
  CODING: '/coding',
  FINANCE: '/finance',
  TRY_NEWSLETTER: '/try-newsletter',
  TRY_SUMMARY: '/try-summary',
  TERMS: '/terms',
  PODCASTS: '/podcasts',
  PODCAST: '/podcasts/:id',
}