<template>
  <div>
    <HeaderComponent/>
    <TermsBodyComponent/>
    <FooterComponent/>
  </div>
</template>

<script>
import HeaderComponent from '@/pages/terms/HeaderComponent.vue';
import TermsBodyComponent from '@/pages/terms/TermsBodyComponent.vue';
import FooterComponent from '@/components/FooterComponent.vue';

export default {
  name: 'MainComponent',
  components: {
    HeaderComponent,
    TermsBodyComponent,
    FooterComponent
  }
}
</script>

<style lang="scss" scoped>
</style>