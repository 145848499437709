<template>
  <footer>
    <div class="container-logo-footer">
      <img src="../assets/QuickView-white.svg" alt="logo-footer" />
      <div class="copyright">
        <div>
           <svg
          xmlns="http://www.w3.org/2000/svg"
          height="1em"
          viewBox="0 0 512 512"
        >
          <!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. -->
          <path
            d="M256 48a208 208 0 1 1 0 416 208 208 0 1 1 0-416zm0 464A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM199.4 312.6c-31.2-31.2-31.2-81.9 0-113.1s81.9-31.2 113.1 0c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9c-50-50-131-50-181 0s-50 131 0 181s131 50 181 0c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0c-31.2 31.2-81.9 31.2-113.1 0z"
          />
        </svg>
        2023 QuickView |
        </div>
      
         <div class="contacts">
          <a :href="'mailto:quickview.newsletter@gmail.com'">Contact us</a> |
        </div>

         <div class="terms">
          <a href="/terms">Terms</a>
        </div>

      </div>
    </div>

    <div class="container-text">
      <div class="first-column">
        <div class="title-fc">
          <p>Newsletter</p>
        </div>

        <div class="coding">
          <a href="/coding">Coding</a>
        </div>

        <div class="crypto">
          <a href="/crypto">Crypto</a>
        </div>

        <div class="finance">
          <a href="/finance">Finance</a>
        </div>
      </div>

      <div class="second-column">
        <div class="title-sc">
          <p>Utilities</p>
        </div>

        <div class="try-summary"><a href="/try-summary">Try summary</a></div>

      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "FooterComponent",
};
</script>

<style lang="scss" scoped>
footer {
  display: flex;
  align-items: center;
  background: #7b68ee;
  flex-shrink: 0;
  margin-top: 130px;
}

.copyright{
  display:flex;
}

.container-logo-footer {
  margin: 0px;
  padding-left: 38px;
  padding-bottom: 80px;
  padding-top: 40px;

  img {
    width: 250px;
  }

  svg {
    fill: white;
    margin-bottom: 2px;
  }

  .copyright {
    color: white;
    padding-top: 15px;
  }
}

.container-text {
  padding-right: 32px;
  color: white;
  display: flex;
  margin-right: 0px;
  font-style: normal;
  font-weight: 400;
  width: 50%;
  padding-top: 40px;
}

.title-fc,
.title-sc {
  margin-bottom: 20px;
  font-size: 20px;
}

.contacts,
.terms{
  padding-left: 5px;
}

.crypto,
.coding,
.contacts,
.terms,
.try-summary {
  margin-bottom: 15px;
}

.crypto a,
.coding a,
.finance a,
.contacts a,
.terms a,
.try-summary a {
  color: #fff;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-decoration-line: underline;
  font-family: "AxiformaRegular";
}

@media (max-width: 700px) {
  footer {
    flex-direction: column !important;
    align-items: flex-start !important;
    margin-top: 30px;

    .container-logo-footer {
      margin-right: 90px;
      padding-top: 50px !important;
      padding-bottom: 50px;

      img {
        width: 150px;
      }
    }

    .copyright{
      width: 315px;
    }

    .container-text {
      flex-wrap: wrap;
      padding-top: 0px;
      margin: 0;
      width: 100%;
    }

    .first-column,
    .second-column {
      width: 100%;
      padding-left: 38px;
    }

    .second-column {
      padding-top: 50px;
      padding-bottom: 20px;
    }
  }
}
</style>
