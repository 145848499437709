import {LANDINGS_PATH} from "@/constants.js";

export const COPY_BY_LANDING_ENGLISH = {
  'requestAccessButton': {
    [LANDINGS_PATH.GENERAL]: 'Request access',
    [LANDINGS_PATH.CRYPTO]: 'Request access',
    [LANDINGS_PATH.CODING]: 'Request access',
    [LANDINGS_PATH.FINANCE]: 'Request access'
  },
  'requestAccessInput': {
    [LANDINGS_PATH.GENERAL]: 'Enter your email',
    [LANDINGS_PATH.CRYPTO]: 'Enter your email',
    [LANDINGS_PATH.CODING]: 'Enter your email',
    [LANDINGS_PATH.FINANCE]: 'Enter your email',
  },
  'earlyAccess': {
    [LANDINGS_PATH.GENERAL]: '🎉QuickView is open for early access',
    [LANDINGS_PATH.CRYPTO]: '🎉QuickView is open for early access',
    [LANDINGS_PATH.CODING]: '🎉QuickView is open for early access',
    [LANDINGS_PATH.FINANCE]: '🎉QuickView is open for early access'
  },
  'mainMessage': {
    [LANDINGS_PATH.GENERAL]: 'Never miss a video again, harness AI-based summaries',
    [LANDINGS_PATH.CRYPTO]: "Never miss a Crypto update, YouTube's top creators in your inbox!",
    [LANDINGS_PATH.CODING]: "Never miss a Tech update, YouTube's top creators in your inbox!",
    [LANDINGS_PATH.FINANCE]: "Never miss a Finance update, YouTube's top creators in your inbox!"
  },
  'subtitle': {
    [LANDINGS_PATH.GENERAL]: 'We use Artificial Intelligence to send you daily video summaries directly to your inbox.',
    [LANDINGS_PATH.CRYPTO]: 'We use Artificial Intelligence to send you daily video summaries directly to your inbox.',
    [LANDINGS_PATH.CODING]: 'We use Artificial Intelligence to send you daily video summaries directly to your inbox.',
    [LANDINGS_PATH.FINANCE]: 'We use Artificial Intelligence to send you daily video summaries directly to your inbox.',
  },
  'check1': {
    [LANDINGS_PATH.GENERAL]: 'Maximize free time',
    [LANDINGS_PATH.CRYPTO]: 'Maximize free time',
    [LANDINGS_PATH.CODING]: 'Maximize free time',
    [LANDINGS_PATH.FINANCE]: 'Maximize free time',
  },
  'check2': {
    [LANDINGS_PATH.GENERAL]: 'Login with your google account',
    [LANDINGS_PATH.CRYPTO]: 'Harnesses the power of AI',
    [LANDINGS_PATH.CODING]: 'Harnesses the power of AI',
    [LANDINGS_PATH.FINANCE]: 'Harnesses the power of AI',
  },
  'check3': {
    [LANDINGS_PATH.GENERAL]: 'Stay up to date',
    [LANDINGS_PATH.CRYPTO]: 'Stay up to date',
    [LANDINGS_PATH.CODING]: 'Stay up to date',
    [LANDINGS_PATH.FINANCE]: 'Stay up to date'
  },
  'check4': {
    [LANDINGS_PATH.GENERAL]: 'Reduce time spent on uninteresting videos',
    [LANDINGS_PATH.CRYPTO]: 'Reduce time spent on uninteresting videos',
    [LANDINGS_PATH.CODING]: 'Reduce time spent on uninteresting videos',
    [LANDINGS_PATH.FINANCE]: 'Reduce time spent on uninteresting videos',
  },
  'instruction1Title': {
    [LANDINGS_PATH.GENERAL]: 'Tailored Timing',
    [LANDINGS_PATH.CRYPTO]: 'Tailored Timing',
    [LANDINGS_PATH.CODING]: 'Tailored Timing',
    [LANDINGS_PATH.FINANCE]: 'Tailored Timing',
  },
  'instruction1Text': {
    [LANDINGS_PATH.GENERAL]: 'Determine the specific timing for receiving your newsletters, ensuring you receive updates at your preferred convenience.',
    [LANDINGS_PATH.CRYPTO]: 'Determine the specific timing for receiving your newsletters, ensuring you receive updates at your preferred convenience.',
    [LANDINGS_PATH.CODING]: 'Determine the specific timing for receiving your newsletters, ensuring you receive updates at your preferred convenience.',
    [LANDINGS_PATH.FINANCE]: 'Determine the specific timing for receiving your newsletters, ensuring you receive updates at your preferred convenience.'
  },
  'instruction2Title': {
    [LANDINGS_PATH.GENERAL]: 'Video Control',
    [LANDINGS_PATH.CRYPTO]: 'Video Control',
    [LANDINGS_PATH.CODING]: 'Video Control',
    [LANDINGS_PATH.FINANCE]: 'Video Control',
  },
  'instruction2Text': {
    [LANDINGS_PATH.GENERAL]: "You're in charge! Personalize the quantity of videos in your email, giving you complete control over the content you receive.",
    [LANDINGS_PATH.CRYPTO]: "You're in charge! Personalize the quantity of videos in your email, giving you complete control over the content you receive.",
    [LANDINGS_PATH.CODING]: "You're in charge! Personalize the quantity of videos in your email, giving you complete control over the content you receive.",
    [LANDINGS_PATH.FINANCE]: "You're in charge! Personalize the quantity of videos in your email, giving you complete control over the content you receive.",
  },
  'instruction3Title': {
    [LANDINGS_PATH.GENERAL]: 'Personalized Selection',
    [LANDINGS_PATH.CRYPTO]: 'Personalized Selection',
    [LANDINGS_PATH.CODING]: 'Personalized Selection',
    [LANDINGS_PATH.FINANCE]: 'Personalized Selection',
  },
  'instruction3Text': {
    [LANDINGS_PATH.GENERAL]: 'Select your favorite YouTube channels and get their top content delivered to your inbox daily.',
    [LANDINGS_PATH.CRYPTO]: 'Select your favorite YouTube channels and get their top content delivered to your inbox daily.',
    [LANDINGS_PATH.CODING]: 'Select your favorite YouTube channels and get their top content delivered to your inbox daily.',
    [LANDINGS_PATH.FINANCE]: 'Select your favorite YouTube channels and get their top content delivered to your inbox daily.',
  },
  'instruction4Title': {
    [LANDINGS_PATH.GENERAL]: 'Summary Highlights',
    [LANDINGS_PATH.CRYPTO]: 'Summary Highlights',
    [LANDINGS_PATH.CODING]: 'Summary Highlights',
    [LANDINGS_PATH.FINANCE]: 'Summary Highlights',
  },
  'instruction4Text': {
    [LANDINGS_PATH.GENERAL]: 'Explore the forefront capabilities of AI as it transforms each video into compelling summaries. Say farewell to the sense of being overwhelmed by videos.',
    [LANDINGS_PATH.CRYPTO]: 'Explore the forefront capabilities of AI as it transforms each video into compelling summaries. Say farewell to the sense of being overwhelmed by videos.',
    [LANDINGS_PATH.CODING]: 'Explore the forefront capabilities of AI as it transforms each video into compelling summaries. Say farewell to the sense of being overwhelmed by videos.',
    [LANDINGS_PATH.FINANCE]: 'Explore the forefront capabilities of AI as it transforms each video into compelling summaries. Say farewell to the sense of being overwhelmed by videos.',
  },
}