<template>
  <div @click="redirectToHomepage">
    <img src="@/assets/QuickView-color.svg" alt="logo" />
  </div>
</template>

<script>
export default {
  name: "LogoComponent",

   methods: {
    redirectToHomepage() {
      this.$router.push('/');
    }
  }
};
</script>

<style lang="scss" scoped>
img {
  width: 250px;
}

@media (max-width: 600px) {
  img {
    width: 200px;
  }
}

@media (max-width: 399px) {
  img {
    width: 150px;
  }
}
</style>
