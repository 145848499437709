import {COPY_BY_LANDING_ENGLISH} from "@/copy/en.js";

export const LANGUAGES = {
  ENGLISH: 'en'
}

const LANGUAGE_TO_COPY = {
 [LANGUAGES.ENGLISH]: COPY_BY_LANDING_ENGLISH
}

export const getCopy = ({field, landing_path, language=LANGUAGES.ENGLISH}) => {
  return LANGUAGE_TO_COPY[language]?.[field]?.[landing_path] || 'lorem ipsum dolor sit amet'
}