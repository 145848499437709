<template>
  <div>
    <HeaderComponent/>
    <MessageComponent/>
    <FormComponent/>
    <InstructionsComponent/>
    <FooterComponent/>
  </div>
</template>

<script>
import HeaderComponent from './HeaderComponent.vue';
import MessageComponent from './MessageComponent.vue';
import FormComponent from './FormComponent.vue';
import InstructionsComponent from './InstructionsComponent.vue';
import FooterComponent from './FooterComponent.vue';

export default {
  name: 'MainComponent',
  components: {
    HeaderComponent,
    MessageComponent,
    FormComponent,
    InstructionsComponent,
    FooterComponent
  }
}
</script>

<style lang="scss" scoped>
</style>