<template>
  <header>
    <div class="container-fluid">
      <div class="box-logo">
        <LogoComponent class="pointer" />
      </div>

      <div class="box-btn">
        <button>
            <a target="_self" href="/try-summary">Try for free</a>
        </button>
      </div>
    </div>
  </header>
</template>

<script>
import LogoComponent from "./LogoComponent.vue";
import { getCopy } from "@/copy/index.js";

export default {
  name: "HeaderComponent",
  components: {
    LogoComponent,
  },
  computed: {
    requestAccessButton() {
      const path = this.$route.path;
      return getCopy({ field: "requestAccessButton", landing_path: path });
    },
  },
};
</script>

<style lang="scss" scoped>
header {
  position: sticky;
  z-index: 90;
  inset-block-start: -1px;
  inline-size: 100%;
  height: 70px;
  background-color: white;
}

.container-fluid {
  display: flex;
  align-items: center;
  padding-top: 5px;
  justify-content: space-between;
}

.box-logo,
.box-btn {
  margin: 0px;
}

.box-logo {
  padding-left: 38px;
  margin-top: 6px;
}

.box-btn {
  padding-right: 33px;
}

button {
  display: inline-flex;
  padding: 9px 23.227px 6.407px 23.227px;
  justify-content: center;
  align-items: center;
  border-radius: 20.023px;
  border:#7b68ee;
  background: #7b68ee;
  box-shadow: 0px 3.2037px 3.2037px 0px rgba(0, 0, 0, 0.25);
}

a {
    color: white;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-decoration: none;
  }

@media (max-width: 600px) {

  header{
    height: 113px;
  }

  .container-fluid{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 30px 20px 30px 20px;
  }

  .box-logo {
    padding-top:0px;
    padding-left:0px;
    margin-top: 0px;
  
  }

  .box-btn {
    padding-right: 0px;
  }
}

.pointer {
  cursor: pointer;
}
</style>
