<template>
  <div>
    <HeaderComponent/>
    <TextComponent/>
    <!--<FormComponent/> -->
    <FooterComponent/>
  </div>
</template>

<script>
import HeaderComponent from '@/pages/try-summary/HeaderComponent.vue';
import TextComponent from '@/pages/try-summary/TextComponent.vue';
//import FormComponent from '@/pages/try-summary/FormComponent.vue';
import FooterComponent from '@/components/FooterComponent.vue';


export default {
  name: 'MainComponent',
  components: {
    HeaderComponent,
    TextComponent,
   // FormComponent,
    FooterComponent
  }
}
</script>

<style lang="scss" scoped>
</style>