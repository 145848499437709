import { render, staticRenderFns } from "./LogoComponent.vue?vue&type=template&id=e1139770&scoped=true&"
import script from "./LogoComponent.vue?vue&type=script&lang=js&"
export * from "./LogoComponent.vue?vue&type=script&lang=js&"
import style0 from "./LogoComponent.vue?vue&type=style&index=0&id=e1139770&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e1139770",
  null
  
)

export default component.exports