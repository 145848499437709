<template>
  <div>
    <HeaderComponent/>
    <p>Title: "{{ podcastInfo.title }}"</p>
    <p v-if="!!podcastInfo.author">Author: "{{ podcastInfo.author }}"</p>
    <div id="selectLanguageSection">
      <label for="languageSelect">Select language:</label>
      <select id="languageSelect" v-model="selectedLanguage" @change="onLanguageChange">
        <option v-for="language in languages" :value="language" :key="language.id">{{ language.name }}</option>
      </select>
    </div>
    <audio v-if="audioUrl" ref="audioPlayer" controls @timeupdate="updateSubtitle" @canplay="audioCanPlay" @play="audioPlay" @pause="audioPause">
      <source :src="audioUrl" type="audio/mpeg">
    </audio>
    <SpinnerComponent v-if="isLoadingPlayerAudio && !isSafari"/>
    <div id="controlPlayerAudio">
      <button id="move-audio-button" @click="seekBackward">- 5s</button>
      <button id="play-pause-button" @click="togglePause">{{ isAudioPaused ? 'Play' : 'Pause' }}</button>
      <button id="move-audio-button" @click="seekForward">+ 5s</button>
      <label for="playbackSpeedSelect">Speed:</label>
      <select id="playbackSpeedSelect" v-model="playbackSpeed" @change="changePlaybackSpeed">
        <option value=1>1x</option>
        <option value=1.5>1.5x</option>
        <option value=1.75>1.75x</option>
        <option value=2>2x</option>
        <option value=3.1415>3.1415x 🐴</option>
      </select>
    </div>
    <div id="bookmarkSection">
      <form @submit.prevent="bookmark">
        <input v-model="email" type="text" placeholder="Email" @input="validateEmail" class="long-input"/>
        <button type="submit" v-if="!isLoading" :disabled="!isEmailValid || !currentSubtitle.text">Bookmark</button>
        <SpinnerComponent v-if="isLoading"/>
      </form>
    </div>
    <div id="subtitleContainer">
      <p id="subtitleText">{{ currentSubtitle.text }}</p>
      <p id="speakerInfo" v-if="currentSubtitle.speaker_id">Speaker: {{ currentSubtitle.speaker_id }}</p>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import HeaderComponent from "@/pages/_podcast/HeaderComponent.vue";
import SpinnerComponent from "@/pages/_podcast/SpinnerComponent.vue";
import router from "@/router"; // Assicurati di importare il router dal tuo file di routing

export default {
  components: {HeaderComponent, SpinnerComponent},
  data() {
    return {
      podcastId: null,
      audioUrl: null,
      podcastInfo: null,
      email: '',
      languages: [],
      isAudioPaused: true,
      selectedLanguage: null,
      isLoadingPlayerAudio: true,
      playbackSpeed: 1,
      isLoading: false,
      isEmailValid: process.env.VUE_APP_MODE === "development",
      currentSubtitle: {text: '', speaker_id: ''},
      subtitles: [],
      isSafari: !navigator.userAgent.includes('Chrome') && navigator.userAgent.includes('Safari'),
    };
  },
  async created() {
    this.podcastId = parseInt(this.$route.params.id);

    await this.fetchPodcastInfo();
    await this.setAudioTime();
    await this.fetchLanguages();

    const languageId = parseInt(this.$route.query.language_id);

    this.selectedLanguage = this.languages.find(language => language.id === languageId);

    if (!this.selectedLanguage) {
      this.selectedLanguage = this.languages[0];
    }

    await this.fetchSubtitles(this.selectedLanguage.id);
  },
  methods: {
    audioCanPlay() {
      this.isLoadingPlayerAudio = false;
    },
    async fetchPodcastInfo() {
      try {
        const response = await axios.get(`${process.env.VUE_APP_BACKEND_URL}/api/v1/podcasts/${this.podcastId}`);
        this.podcastInfo = response.data;
        this.audioUrl = response.data.audio_url;
      } catch (error) {
        console.error("API error:", error);
      }
    },
    audioPlay() {
      this.isAudioPaused = false;
    },

    audioPause() {
      this.isAudioPaused = true;
    },
    setAudioTime() {
      const audioPlayer = this.$refs.audioPlayer;
      const currentTime = this.getQueryParam();
      if (currentTime > 0) {
        audioPlayer.currentTime = currentTime;
      }
    },
    async fetchLanguages() {
      try {
        const response = await axios.get(`${process.env.VUE_APP_BACKEND_URL}/api/v1/podcasts/${this.podcastId}/subtitles/languages`);
        this.languages = response.data;
      } catch (error) {
        console.error("API error:", error);
      }
    },
    async fetchSubtitles(language_id) {
      let url = `${process.env.VUE_APP_BACKEND_URL}/api/v1/podcasts/${this.podcastId}/subtitles`

      language_id ||= ''
      if (language_id) {
        url += `?language_id=${language_id}`
      }

      try {
        const response = await axios.get(url);
        this.subtitles = response.data;
      } catch (error) {
        console.error("API error:", error);
      }
    },
    getQueryParam() {
      const timeParam = this.$route.query.seconds;
      return timeParam || 0;
    },
    updateSubtitle() {
      const audioPlayer = this.$refs.audioPlayer;
      const currentTime = audioPlayer.currentTime * 10_000_000;
      const currentSubtitle = this.subtitles.find(subtitle =>
          currentTime >= subtitle.offset && currentTime <= subtitle.offset + subtitle.duration
      );
      if (currentSubtitle) {
        this.currentSubtitle = currentSubtitle;
      } else {
        this.currentSubtitle = {text: '', offset: null, speaker_id: ''};
      }
    },
    seekForward() {
      this.seekBySeconds(5);
    },
    togglePause() {
      const audioPlayer = this.$refs.audioPlayer;
      if (audioPlayer.paused) {
        audioPlayer.play();
        this.isAudioPaused = false;
      } else {
        audioPlayer.pause();
        this.isAudioPaused = true;
      }
    },
    seekBackward() {
      this.seekBySeconds(-5);
    },
    seekBySeconds(seconds) {
      const audioPlayer = this.$refs.audioPlayer;
      audioPlayer.currentTime += seconds;
    },
    changePlaybackSpeed() {
      const audioPlayer = this.$refs.audioPlayer;
      audioPlayer.playbackRate = parseFloat(this.playbackSpeed);
    },
    async onLanguageChange() {
      await this.fetchSubtitles(this.selectedLanguage.id);
      this.updateSubtitle();
      await router.replace({query: {...this.$route.query, language_id: this.selectedLanguage.id}});
    },
    async bookmark() {
      try {
        this.isLoading = true;

        const language_id = this.selectedLanguage.id;
        const seconds = Math.floor(this.currentSubtitle.offset / 10_000_000);
        const link = `${process.env.VUE_APP_HOST_URL}/podcasts/${this.podcastId}?language_id=${language_id}&seconds=${seconds}`;

        const current_subtitle = this.currentSubtitle.text;
        const body = `You have created a bookmark on Podridge 🍲!\n\n🔖 Subtitle:\n"${current_subtitle}"\n\n🎧 Listen Here:\n${link}`

        const data = {
          email: this.email,
          subject: 'Podridge 🍲 - Bookmark created!',
          body,
        };

        if (process.env.VUE_APP_MODE === 'production') {
          await axios.post(
              `${process.env.VUE_APP_BACKEND_URL}/api/send-email`,
              data
          );
        } else {
          alert(body)
        }

        this.isLoading = false;
      } catch (error) {
        console.error("API error:", error);
        this.isLoading = false;
      }
    },
    validateEmail() {
      const emailPattern = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$/;
      this.isEmailValid = emailPattern.test(this.email);
    },
  },
};
</script>

<style scoped>
.long-input {
  width: 300px;
}

#subtitleContainer {
  margin-top: 20px;
}

#subtitleText {
  font-size: 18px;
}

#play-pause-button {
  width: 85px;
}

#move-audio-button {
  width: 70px;
}
</style>
