<template>
  <div>
    <div>
      <select v-model="selectedPodcast" @change="onPodcastChange">
        <option value="">Select podcast</option>
        <option v-for="podcast in podcasts" :value="podcast" :key="podcast.id">{{ podcast.title }}</option>
      </select>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: 'PodcastsComponent',
  components: {},
  data() {
    return {
      podcasts: [],
      selectedPodcast: '',
    };
  },
  async created() {
    try {
      const response = await axios.get(`${process.env.VUE_APP_BACKEND_URL}/api/v1/podcasts`);
      this.podcasts = response.data;
    } catch (error) {
      console.error("API error:", error);
    }
  },
  methods: {
    onPodcastChange() {
      this.$router.push(`/podcasts/${this.selectedPodcast.id}?language_id=${this.selectedPodcast.original_language_id}`);
    },
  },
};
</script>