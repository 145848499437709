<template>
  <div class="container-text-newsletter">
    <h1 class="title">Try our free AI-powered newsletter!</h1>
    <p class="text">Experience a sneak peek of cutting-edge content and exclusive insights driven by artificial intelligence.</p>
    <p class="text-2">Join us today and explore a preview of what's to come.</p>
  </div>
</template>
<script>
export default {
  name: "TextComponent",
};
</script>
<style lang="scss" scoped>
.container-text-newsletter{
  padding-left:40px;

  .title{
    padding-top:50px;
  }
  
  .text, .text-2{
    font-family: "AxiformaRegular", sans-serif;
  }

  .text{
     padding-top: 10px;
  }

}

@media (max-width: 600px) {
  .container-text-newsletter{
    padding-left:20px;
  }
  
}

</style>