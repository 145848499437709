<template>
  <div class="container-instructions d-flex flex-wrap">
    <div class="box-1">
      <h4>{{ instruction1Title }}</h4>

      <div class="br br-1"></div>

      <div>
        <p>{{ instruction1Text }}</p>
      </div>
    </div>

    <div class="box-2">
      <div>
        <h4>{{ instruction2Title }}</h4>

        <div class="br br-2"></div>

        <div>
          <p>{{ instruction2Text }}</p>
        </div>
      </div>
    </div>

    <div class="box-3">
      <div>
        <h4>{{ instruction3Title }}</h4>

        <div class="br br-3"></div>

        <div>
          <p>{{ instruction3Text }}</p>
        </div>
      </div>
    </div>

    <div class="box-4">
      <div>
        <h4>{{ instruction4Title }}</h4>

        <div class="br br-4"></div>

        <div>
          <p>{{ instruction4Text }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getCopy } from "@/copy/index.js";

export default {
  name: "InstructionsComponent",
  computed: {
    instruction1Title() {
      const path = this.$route.path;
      return getCopy({ field: "instruction1Title", landing_path: path });
    },
    instruction1Text() {
      const path = this.$route.path;
      return getCopy({ field: "instruction1Text", landing_path: path });
    },
    instruction2Title() {
      const path = this.$route.path;
      return getCopy({ field: "instruction2Title", landing_path: path });
    },
    instruction2Text() {
      const path = this.$route.path;
      return getCopy({ field: "instruction2Text", landing_path: path });
    },
    instruction3Title() {
      const path = this.$route.path;
      return getCopy({ field: "instruction3Title", landing_path: path });
    },
    instruction3Text() {
      const path = this.$route.path;
      return getCopy({ field: "instruction3Text", landing_path: path });
    },
    instruction4Title() {
      const path = this.$route.path;
      return getCopy({ field: "instruction4Title", landing_path: path });
    },
    instruction4Text() {
      const path = this.$route.path;
      return getCopy({ field: "instruction4Text", landing_path: path });
    },
  },
};
</script>

<style lang="scss" scoped>
.container-instructions {
  display: flex;
  padding-top: 47px;
  padding-bottom: 50px;
}

.box-1,
.box-2,
.box-3,
.box-4 {
  width: 320px;
  height: 278px;
  flex-shrink: 0;
  padding-left: 19px;
}

.box-1 {
  border-radius: 20px;
  background: #bdf0e2;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);

  p {
    width: 282px;
    margin: 0px;
  }
}

.box-2 {
  border-radius: 20px;
  background: #cdc5f6;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.box-3 {
  border-radius: 20px;
  background: #f6c4da;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.box-4 {
  border-radius: 20px;
  background: #c5e9f4;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);

  p {
    width: 282px;
    margin: 0px;
  }
}

.br {
  width: 91px;
  height: 3px;
  margin-bottom: 11px;
  margin-left: 0px;
}

.br-1 {
  background: #00bc89;
}

.br-2 {
  background: #1f00bf;
}

.br-3 {
  background: #be0054;
}

.br-4 {
  background: #0092bf;
}

h4 {
  color: #292d34;
  font-size: 24px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  padding-top: 65px;
  margin: 0px;
}

p {
  color: #292d34;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  font-family: "AxiformaRegular", sans-serif;
}

.box-2 p {
  width: 247px;
  margin: 0px;
}

@media (max-width: 1199px) {
  .box-1,
  .box-2 {
    margin-bottom: 50px;
  }
}

@media (max-width: 690px) {
  .container-instructions {
    display: flex;
    flex-direction: column;
    padding-top: 26px;

    h4 {
      font-size: 24px;
    }

    .box-1,
    .box-2,
    .box-3 {
      margin-bottom: 32px;
    }

    .box-3{
      h4{
        width:50%;
      }
    }
  }
}
</style>
