<template>
  <div class="container-form d-flex">
    <div class="subcontainer-form">
      <form @submit.prevent="handleSubmit" class="d-flex">
        <div class="mb-3 container-input d-flex">
          <input
            type="email"
            v-model="email"
            class="form-control"
            id="exampleInputEmail1"
            aria-describedby="emailHelp"
            :placeholder="requestAccessInput"
            required
          />
        </div>
        <!-- Spinner (mostrato solo quando isLoading è true) -->
        <div v-if="isLoading" class="spinner">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            height="2em"
            viewBox="0 0 512 512"
            class="white-spinner rotate"
          >
            <!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. -->
            <path
              d="M222.7 32.1c5 16.9-4.6 34.8-21.5 39.8C121.8 95.6 64 169.1 64 256c0 106 86 192 192 192s192-86 192-192c0-86.9-57.8-160.4-137.1-184.1c-16.9-5-26.6-22.9-21.5-39.8s22.9-26.6 39.8-21.5C434.9 42.1 512 140 512 256c0 141.4-114.6 256-256 256S0 397.4 0 256C0 140 77.1 42.1 182.9 10.6c16.9-5 34.8 4.6 39.8 21.5z"
            />
          </svg>
        </div>
        <div v-if="showSuccessMessage && !isLoading" class="success-message">
          Request submitted.
        </div>
        <div class="container-btn">
          <button
            type="submit"
            class="btn"
            v-if="!showSuccessMessage && !isLoading"
          >
            {{ requestAccessButton }}
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { getCopy } from "@/copy/index.js";
import { LANDINGS_PATH } from "@/constants.js";

export default {
  name: "FormComponent",
  computed: {
    requestAccessButton() {
      const path = this.$route.path;
      return getCopy({ field: "requestAccessButton", landing_path: path });
    },
    requestAccessInput() {
      const path = this.$route.path;
      return getCopy({ field: "requestAccessInput", landing_path: path });
    },
  },
  data() {
    return {
      email: "",
      showSuccessMessage: false,
      isLoading: false,
    };
  },
  methods: {
    async handleSubmit() {
      try {
        this.isLoading = true;

        const path = this.$route.path;

        const PATH_TO_LANDING = {
          [LANDINGS_PATH.GENERAL]: "general",
          [LANDINGS_PATH.CRYPTO]: "crypto",
          [LANDINGS_PATH.CODING]: "coding",
          [LANDINGS_PATH.FINANCE]: "finance",
        };

        const data = {
          email: this.email,
          landing: PATH_TO_LANDING[path],
        };

        await axios.post(
          `${process.env.VUE_APP_BACKEND_URL}/api/contact-email`,
          data
        );

        this.isLoading = false;
        this.showSuccessMessage = true;
      } catch (error) {
        console.error("API error:", error);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.container-form {
  .form-control {
    display: flex;
    width: 320px;
    padding: 9px 23.841px 6.577px 23.841px;
    justify-content: center;
    align-items: center;
    border-radius: 20.553px;
    border: 1.026px solid #7b68ee;
    background: #fff;
    box-shadow: 0px 3.28842px 3.28842px 0px rgba(0, 0, 0, 0.25);
    margin-right: 32px;
    text-align: center;
  }

  form {
    padding-top: 48px;
  }

  button {
    color: white;
    display: inline-flex;
    padding: 9px 23.227px 6.407px 23.227px;
    justify-content: center;
    align-items: center;
    border-radius: 20.023px;
    background: #7b68ee;
    box-shadow: 0px 3.2037px 3.2037px 0px rgba(0, 0, 0, 0.25);
  }

  .white-spinner {
    fill: #7b68ee;
  }
}

.rotate {
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@media (max-width: 690px) {


  .form-control{
    margin-right:0px !important;
  }

   form {
      padding-top: 26px !important;
      flex-direction: column !important;
    }
}
</style>
