import Vue from 'vue';
import VueRouter from 'vue-router';

import NotFound from "@/components/NotFound.vue";
import MainComponent from "@/components/MainComponent.vue";
import MainComponentTryNewsletter from "@/pages/try-newsletter/MainComponent.vue";
import MainComponentTrySummary from "@/pages/try-summary/MainComponent.vue";
import MainComponentTerms from "@/pages/terms/MainComponent.vue";
import _MainComponentPodcasts from "@/pages/_podcasts/MainComponent.vue";
import _MainComponentPodcast from "@/pages/_podcast/MainComponent.vue";

import {LANDINGS_PATH} from "@/constants.js";

Vue.use(VueRouter);

const routes = [
  {
    path: LANDINGS_PATH.GENERAL,
    component: MainComponent,
  },
  {
    path: LANDINGS_PATH.CRYPTO,
    component: MainComponent,
  },
  {
    path: LANDINGS_PATH.CODING,
    component: MainComponent,
  },
  {
    path: LANDINGS_PATH.FINANCE,
    component: MainComponent,
  },
  {
    path: LANDINGS_PATH.TRY_NEWSLETTER,
    component: MainComponentTryNewsletter,
  },
  {
    path: LANDINGS_PATH.TRY_SUMMARY,
    component: MainComponentTrySummary,
  },
  {
    path: LANDINGS_PATH.TERMS,
    component: MainComponentTerms,
  },
  {
    path: LANDINGS_PATH.PODCASTS,
    component: _MainComponentPodcasts,
  },
  {
    path: LANDINGS_PATH.PODCAST,
    component: _MainComponentPodcast,
  },
  {
    path: '*',
    component: NotFound,
  },
];

const router = new VueRouter({
  mode: 'history',
  routes,
});

export default router;
