var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container-terms"},[_c('h1',{staticClass:"title-terms"},[_vm._v("Privacy Policy")]),_c('p',{staticClass:"subtitle-terms"},[_vm._v("This Privacy Policy explains how we collect, use, and disclose your personal information when you visit our website. By using our website, you consent to the terms of this policy.")]),_vm._m(0),_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_vm._m(6),_vm._m(7),_c('div',{staticClass:"points-terms"},[_c('h5',[_vm._v("9. Contact Us")]),_c('p',[_vm._v("If you have any questions about this Privacy Policy, please contact us at "),_c('a',{attrs:{"href":'mailto:quickview.newsletter@gmail.com'}},[_vm._v("quickview.newsletter@gmail.com")]),_vm._v(".")])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"points-terms"},[_c('h5',[_vm._v("1. Information We Collect")]),_c('p',[_vm._v("We may collect personal information such as your name, email address, and other contact information when you voluntarily provide it to us through our website.")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"points-terms"},[_c('h5',[_vm._v("2. How We Use Your Information")]),_c('p',[_vm._v("We use your personal information to respond to your inquiries, to provide you with information about our products and services, and to improve the content and functionality of our website.")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"points-terms"},[_c('h5',[_vm._v("3. Cookies and Other Tracking Technologies")]),_c('p',[_vm._v("We use cookies and other tracking technologies to collect information about your use of our website. This information may include your IP address, browser type, operating system, and other information about your device. We use this information to improve the content and functionality of our website and to personalize your experience.")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"points-terms"},[_c('h5',[_vm._v("4. Sharing Your Information")]),_c('p',[_vm._v("We do not sell or share your personal information with third parties except as required by law or as necessary to fulfill your requests.")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"points-terms"},[_c('h5',[_vm._v("5. Security")]),_c('p',[_vm._v("We take reasonable measures to protect your personal information from unauthorized access, disclosure, or use.")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"points-terms"},[_c('h5',[_vm._v("6. Copyright Infringements")]),_c('p',[_vm._v("If you believe material infringes your copyright, notify us using the contact info below. We'll send a copy to the person responsible. Be aware that false claims may result in liability. Consider consulting an attorney if unsure about infringement.")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"points-terms"},[_c('h5',[_vm._v("7. Children’s Privacy")]),_c('p',[_vm._v("Our website is not intended for children under the age of 13. We do not knowingly collect personal information from children under the age of 13.")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"points-terms"},[_c('h5',[_vm._v("8. Changes to this Privacy Policy")]),_c('p',[_vm._v("We may update this Privacy Policy from time to time. We will post the updated policy on our website and will indicate the date of the most recent update.")])])
}]

export { render, staticRenderFns }