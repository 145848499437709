<template>
  <div class="container-text-form">
    <div class="container-summary">
      <h1 class="title">Try our AI-powered summarizer!</h1>
      <p class="text">
        Experience a sneak peek of cutting-edge content and exclusive insights
        driven by artificial intelligence.
      </p>
      <p class="text-2">
        Join us today and explore a preview of what's to come.
      </p>
    </div>
    <div>
      <FormComponent />
    </div>
  </div>
</template>
<script>

import FormComponent from "./FormComponent.vue";

export default {
  name: "TextComponent",
  components: {
    FormComponent,
  },
};
</script>
<style lang="scss" scoped>

.container-text-form{
  display: flex;
}

.container-summary {
  padding-left: 40px;
  background-image: url('images/bg-trysummary.png');
  margin: 0;
  width: 70%;
  height: 427px;

  .title {
    padding-top: 65px;
    font-size: 60px;
  }

  .text,
  .text-2 {
    font-family: "AxiformaRegular", sans-serif;
    font-size: 16px;
    font-weight: 600;
  }

  .text {
    padding-top: 10px;
    padding-bottom: 30px;
    width: 70%;
    margin: 0;
  }
}

@media(max-width: 982px){
  .container-text-form{
    display: flex;
    flex-direction: column;
  }

  .container-summary{
    width:100% !important;
  }

  .title{
    font-size: 50px;
  }


  .text, .text-2{
    font-size: 20px;
  }

}

@media (max-width: 600px) {
  .container-summary {
    padding-left: 20px;
    padding-right: 20px;
    width: 100% !important;
    background-position: center;

    .title{
      font-size: 24px;
      padding-top: 110px !important;
    }

    .text{
      font-size:13px;
      width: 100% !important;
    }

    .text-2{
      font-size: 13px;
    }
  }
}
</style>
