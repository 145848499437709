<template>
  <div>
    <HeaderComponent/>
    <PodcastsComponent/>
  </div>
</template>

<script>
import HeaderComponent from "@/pages/_podcast/HeaderComponent.vue";
import PodcastsComponent from "@/pages/_podcasts/PodcastsComponent.vue";

export default {
  name: 'MainComponent',
  components: {
    HeaderComponent,
    PodcastsComponent,
  }
}
</script>

<style lang="scss" scoped>
</style>