<template>
  <div>
    <h1>404 - Page not found</h1>
    <router-link to="/">Homepage</router-link>
  </div>
</template>

<script>
export default {
  name: 'NotFound',
};
</script>

<style scoped>
</style>
