<template>
  <div>
    <PodcastComponent/>
  </div>
</template>

<script>
import PodcastComponent from '@/pages/_podcast/PodcastComponent.vue';

export default {
  name: 'MainComponent',
  components: {
    PodcastComponent,
  }
}
</script>

<style lang="scss" scoped>
</style>