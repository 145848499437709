<template>
  <h1>Podridge 🍲</h1>
</template>

<script>
export default {
  name: "HeaderComponent"
}
</script>

<style lang="scss" scoped>

h1 {
  font-size: 24px;
}

</style>