<template>
  <div class="container-terms">

    <h1 class="title-terms">Privacy Policy</h1>

    <p class="subtitle-terms">This Privacy Policy explains how we collect, use, and disclose your personal information when you visit our website. By using our website, you consent to the terms of this policy.</p>

    <div class="points-terms">
      <h5>1. Information We Collect</h5>
      <p>We may collect personal information such as your name, email address, and other contact information when you voluntarily provide it to us through our website.</p>
    </div>

    <div class="points-terms">
      <h5>2. How We Use Your Information</h5>
      <p>We use your personal information to respond to your inquiries, to provide you with information about our products and services, and to improve the content and functionality of our website.</p>
    </div>

    <div class="points-terms">
      <h5>3. Cookies and Other Tracking Technologies</h5>
      <p>We use cookies and other tracking technologies to collect information about your use of our website. This information may include your IP address, browser type, operating system, and other information about your device. We use this information to improve the content and functionality of our website and to personalize your experience.</p>
    </div>

    <div class="points-terms">
      <h5>4. Sharing Your Information</h5>
      <p>We do not sell or share your personal information with third parties except as required by law or as necessary to fulfill your requests.</p>
    </div>

    <div class="points-terms">
      <h5>5. Security</h5>
      <p>We take reasonable measures to protect your personal information from unauthorized access, disclosure, or use.</p>
    </div>

    <div class="points-terms">
      <h5>6. Copyright Infringements</h5>
      <p>If you believe material infringes your copyright, notify us using the contact info below. We'll send a copy to the person responsible. Be aware that false claims may result in liability. Consider consulting an attorney if unsure about infringement.</p>
    </div>

    <div class="points-terms">
      <h5>7. Children’s Privacy</h5>
      <p>Our website is not intended for children under the age of 13. We do not knowingly collect personal information from children under the age of 13.</p>
    </div>

    <div class="points-terms">
      <h5>8. Changes to this Privacy Policy</h5>
      <p>We may update this Privacy Policy from time to time. We will post the updated policy on our website and will indicate the date of the most recent update.</p>
    </div>

    <div class="points-terms">
      <h5>9. Contact Us</h5>
      <p>If you have any questions about this Privacy Policy, please contact us at <a :href="'mailto:quickview.newsletter@gmail.com'">quickview.newsletter@gmail.com</a>.</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "TermsBodyComponent",
};
</script>

<style lang="scss" scoped>

.container-terms{
  padding-left:40px;

  .title-terms{
  padding-top:60px;
}

.subtitle-terms{
  font-family: "AxiformaRegular", sans-serif;
  padding-bottom: 20px;
  padding-top: 10px;
}

.points-terms{
  padding-bottom: 20px;

  h5{
    padding-bottom: 10px;
  }
  p{
    font-family: "AxiformaRegular", sans-serif;
  }
}

}


@media (max-width: 1199px) {
  .container-terms{
   padding-left: 20px;
  }
}

</style>
