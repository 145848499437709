<template>
  <div class="container-fluid-mc">
    <div class="container-message">
      <div class="container-plan">
        <p class="plan-access">🎉QuickView is open for early access</p>
      </div>
      <div class="container-text">
        <h1 class="title">{{ mainMessage }}</h1>
        <p class="subtitle">{{ subtitle }}</p>
        <div class="container-points">
          <div class="d-flex container-box-1">
            <div class="box-points-1">
              <img src="../assets/check.svg" alt="true-3" />
              <div class="box-p-1">
                <p class="p-points-1">{{ check1 }}</p>
              </div>
            </div>

            <div class="box-points-2">
              <img src="../assets/check.svg" alt="true-4" />
              <div>
                <p class="p-points-2">{{ check2 }}</p>
              </div>
            </div>
          </div>

          <div class="d-flex container-box-2">
            <div class="box-points-3">
              <img src="../assets/check.svg" alt="true" />
              <div class="container-p-box-3">
                <p class="p-points-3">{{ check3 }}</p>
              </div>
            </div>

            <div class="box-points-4">
              <img src="../assets/check.svg" alt="true-2" />
              <div>
                <p class="p-points-4">{{ check4 }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getCopy } from "@/copy/index.js";
import { getBackgroundLandingImage } from "@/background-images/index.js";

export default {
  name: "MessageComponent",
  mounted() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    handleResize() {
      this.updateBackgroundImage();
    },
    updateBackgroundImage() {
      const path = this.$route.path;
      const screen_width = window.innerWidth;
      const backgroundImageUrl = require(`@/components/images/${getBackgroundLandingImage(
        path,
        screen_width
      )}`);
      document.querySelector(
        ".container-fluid-mc"
      ).style.backgroundImage = `url(${backgroundImageUrl})`;
    },
  },
  computed: {
    earlyAccess() {
      const path = this.$route.path;
      return getCopy({ field: "earlyAccess", landing_path: path });
    },
    mainMessage() {
      const path = this.$route.path;
      return getCopy({ field: "mainMessage", landing_path: path });
    },
    subtitle() {
      const path = this.$route.path;
      return getCopy({ field: "subtitle", landing_path: path });
    },
    check1() {
      const path = this.$route.path;
      return getCopy({ field: "check1", landing_path: path });
    },
    check2() {
      const path = this.$route.path;
      return getCopy({ field: "check2", landing_path: path });
    },
    check3() {
      const path = this.$route.path;
      return getCopy({ field: "check3", landing_path: path });
    },
    check4() {
      const path = this.$route.path;
      return getCopy({ field: "check4", landing_path: path });
    },
  },
};
</script>

<style lang="scss" scoped>
.container-fluid-mc {
  // background-image: url("@/components/images/image-landing-page.svg");
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
}

.container-message {
  display: flex;
  flex-direction: column;
}
.container-plan {
  display: flex;
  width: 340px;
  border: 1px solid lightgrey;
  border-radius: 25px;
  margin-top: 50px;
  background-color: #fafafa;

  .plan-access {
    margin-bottom: 0px;
    padding: 5px;
    font-size: 13px;
  }
}
.title {
  color: #292d34;
  font-size: 60px;
  font-style: normal;
  font-weight: 800;
  line-height: 80px;
  width: 70%;
  margin: 0;
  padding-top: 44px;
  padding-left: 34px;
}

.subtitle {
  color: #292d34;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin: 0;
  padding-left: 34px;
}

.container-box-1,
.container-box-2 {
  margin: 0;
}

.container-points {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: flex-start;
  padding-bottom: 53px;
  padding-left: 32px;

  .box-points-1 {
    margin-right: 75px;
  }

  .box-points-3 {
    margin-right: 107px;
  }

  .box-points-1,
  .box-points-2,
  .box-points-3,
  .box-points-4 {
    display: flex;
    align-items: flex-start;
    padding-top: 20px;

    img {
      padding-right: 10px;
    }

    .p-points-1,
    .p-points-2,
    .p-points-3,
    .p-points-4 {
      color: #292d34;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }
  }
}

@media (max-width: 1199px) {
  .title {
    font-size: 50px;
  }

  .container-fluid-mc {
    background-position: center;
  }
}

@media (max-width: 690px) {
  .container-fluid-mc {
    background-position: inherit;
  }

  .container-plan {
    margin-top: 44px;
  }
  .title {
    font-size: 22px;
    font-style: normal;
    font-weight: 800;
    line-height: 30px;
    padding-left: 17px;
    width: 350px;
  }

  .subtitle {
    font-size: 13px;
    font-style: normal;
    line-height: normal;
    padding-left: 17px;
    padding-top: 31px;
  }
  .container-box-1,
  .container-box-2 {
    display: flex !important;
    flex-direction: column !important;
  }

  .box-points-1,
  .box-points-2,
  .box-points-3,
  .box-points-4 {
    padding-top: 10px !important;
  }

  .p-points-1,
  .p-points-2,
  .p-points-3,
  .p-points-4 {
    font-size: 13px !important;
    font-style: normal;
    margin-top: 4px;
  }

  .container-points {
    padding: 0px;
    padding-top: 31px;

    .box-points-1 {
      margin-left: 14px;

      img {
        margin: 0px;
      }
    }
    .box-p-1 {
      margin: 0;
    }
    .box-points-2 {
      margin-left: 14px;
    }

    .box-points-4 {
      margin-left: 14px;
      padding-bottom: 38px;
    }

    .box-points-3 {
      margin-left: 14px;
      margin-right: 0px !important;

      img {
        margin: 0px;
      }

      .container-p-box-3 {
        margin: 0;
      }
    }
  }

  .container-box-2 {
    margin-left: 0px;
  }
}
</style>
